/* .loader {
    margin: 100px auto;
    font-size: 20px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: load5 1.1s infinite ease;
    animation: load5 1.1s infinite ease;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  @-webkit-keyframes load5 {
    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em #151414, 1.8em -1.8em 0 0em rgba(21,20,20, 0.2), 2.5em 0em 0 0em rgba(21,20,20, 0.2), 1.75em 1.75em 0 0em rgba(21,20,20, 0.2), 0em 2.5em 0 0em rgba(21,20,20, 0.2), -1.8em 1.8em 0 0em rgba(21,20,20, 0.2), -2.6em 0em 0 0em rgba(21,20,20, 0.5), -1.8em -1.8em 0 0em rgba(21,20,20, 0.7);
    }
    12.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(21,20,20, 0.7), 1.8em -1.8em 0 0em #151414, 2.5em 0em 0 0em rgba(21,20,20, 0.2), 1.75em 1.75em 0 0em rgba(21,20,20, 0.2), 0em 2.5em 0 0em rgba(21,20,20, 0.2), -1.8em 1.8em 0 0em rgba(21,20,20, 0.2), -2.6em 0em 0 0em rgba(21,20,20, 0.2), -1.8em -1.8em 0 0em rgba(21,20,20, 0.5);
    }
    25% {
      box-shadow: 0em -2.6em 0em 0em rgba(21,20,20, 0.5), 1.8em -1.8em 0 0em rgba(21,20,20, 0.7), 2.5em 0em 0 0em #151414, 1.75em 1.75em 0 0em rgba(21,20,20, 0.2), 0em 2.5em 0 0em rgba(21,20,20, 0.2), -1.8em 1.8em 0 0em rgba(21,20,20, 0.2), -2.6em 0em 0 0em rgba(21,20,20, 0.2), -1.8em -1.8em 0 0em rgba(21,20,20, 0.2);
    }
    37.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(21,20,20, 0.2), 1.8em -1.8em 0 0em rgba(21,20,20, 0.5), 2.5em 0em 0 0em rgba(21,20,20, 0.7), 1.75em 1.75em 0 0em #151414, 0em 2.5em 0 0em rgba(21,20,20, 0.2), -1.8em 1.8em 0 0em rgba(21,20,20, 0.2), -2.6em 0em 0 0em rgba(21,20,20, 0.2), -1.8em -1.8em 0 0em rgba(21,20,20, 0.2);
    }
    50% {
      box-shadow: 0em -2.6em 0em 0em rgba(21,20,20, 0.2), 1.8em -1.8em 0 0em rgba(21,20,20, 0.2), 2.5em 0em 0 0em rgba(21,20,20, 0.5), 1.75em 1.75em 0 0em rgba(21,20,20, 0.7), 0em 2.5em 0 0em #151414, -1.8em 1.8em 0 0em rgba(21,20,20, 0.2), -2.6em 0em 0 0em rgba(21,20,20, 0.2), -1.8em -1.8em 0 0em rgba(21,20,20, 0.2);
    }
    62.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(21,20,20, 0.2), 1.8em -1.8em 0 0em rgba(21,20,20, 0.2), 2.5em 0em 0 0em rgba(21,20,20, 0.2), 1.75em 1.75em 0 0em rgba(21,20,20, 0.5), 0em 2.5em 0 0em rgba(21,20,20, 0.7), -1.8em 1.8em 0 0em #151414, -2.6em 0em 0 0em rgba(21,20,20, 0.2), -1.8em -1.8em 0 0em rgba(21,20,20, 0.2);
    }
    75% {
      box-shadow: 0em -2.6em 0em 0em rgba(21,20,20, 0.2), 1.8em -1.8em 0 0em rgba(21,20,20, 0.2), 2.5em 0em 0 0em rgba(21,20,20, 0.2), 1.75em 1.75em 0 0em rgba(21,20,20, 0.2), 0em 2.5em 0 0em rgba(21,20,20, 0.5), -1.8em 1.8em 0 0em rgba(21,20,20, 0.7), -2.6em 0em 0 0em #151414, -1.8em -1.8em 0 0em rgba(21,20,20, 0.2);
    }
    87.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(21,20,20, 0.2), 1.8em -1.8em 0 0em rgba(21,20,20, 0.2), 2.5em 0em 0 0em rgba(21,20,20, 0.2), 1.75em 1.75em 0 0em rgba(21,20,20, 0.2), 0em 2.5em 0 0em rgba(21,20,20, 0.2), -1.8em 1.8em 0 0em rgba(21,20,20, 0.5), -2.6em 0em 0 0em rgba(21,20,20, 0.7), -1.8em -1.8em 0 0em #151414;
    }
  }
  @keyframes load5 {
    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em #151414, 1.8em -1.8em 0 0em rgba(21,20,20, 0.2), 2.5em 0em 0 0em rgba(21,20,20, 0.2), 1.75em 1.75em 0 0em rgba(21,20,20, 0.2), 0em 2.5em 0 0em rgba(21,20,20, 0.2), -1.8em 1.8em 0 0em rgba(21,20,20, 0.2), -2.6em 0em 0 0em rgba(21,20,20, 0.5), -1.8em -1.8em 0 0em rgba(21,20,20, 0.7);
    }
    12.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(21,20,20, 0.7), 1.8em -1.8em 0 0em #151414, 2.5em 0em 0 0em rgba(21,20,20, 0.2), 1.75em 1.75em 0 0em rgba(21,20,20, 0.2), 0em 2.5em 0 0em rgba(21,20,20, 0.2), -1.8em 1.8em 0 0em rgba(21,20,20, 0.2), -2.6em 0em 0 0em rgba(21,20,20, 0.2), -1.8em -1.8em 0 0em rgba(21,20,20, 0.5);
    }
    25% {
      box-shadow: 0em -2.6em 0em 0em rgba(21,20,20, 0.5), 1.8em -1.8em 0 0em rgba(21,20,20, 0.7), 2.5em 0em 0 0em #151414, 1.75em 1.75em 0 0em rgba(21,20,20, 0.2), 0em 2.5em 0 0em rgba(21,20,20, 0.2), -1.8em 1.8em 0 0em rgba(21,20,20, 0.2), -2.6em 0em 0 0em rgba(21,20,20, 0.2), -1.8em -1.8em 0 0em rgba(21,20,20, 0.2);
    }
    37.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(21,20,20, 0.2), 1.8em -1.8em 0 0em rgba(21,20,20, 0.5), 2.5em 0em 0 0em rgba(21,20,20, 0.7), 1.75em 1.75em 0 0em #151414, 0em 2.5em 0 0em rgba(21,20,20, 0.2), -1.8em 1.8em 0 0em rgba(21,20,20, 0.2), -2.6em 0em 0 0em rgba(21,20,20, 0.2), -1.8em -1.8em 0 0em rgba(21,20,20, 0.2);
    }
    50% {
      box-shadow: 0em -2.6em 0em 0em rgba(21,20,20, 0.2), 1.8em -1.8em 0 0em rgba(21,20,20, 0.2), 2.5em 0em 0 0em rgba(21,20,20, 0.5), 1.75em 1.75em 0 0em rgba(21,20,20, 0.7), 0em 2.5em 0 0em #151414, -1.8em 1.8em 0 0em rgba(21,20,20, 0.2), -2.6em 0em 0 0em rgba(21,20,20, 0.2), -1.8em -1.8em 0 0em rgba(21,20,20, 0.2);
    }
    62.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(21,20,20, 0.2), 1.8em -1.8em 0 0em rgba(21,20,20, 0.2), 2.5em 0em 0 0em rgba(21,20,20, 0.2), 1.75em 1.75em 0 0em rgba(21,20,20, 0.5), 0em 2.5em 0 0em rgba(21,20,20, 0.7), -1.8em 1.8em 0 0em #151414, -2.6em 0em 0 0em rgba(21,20,20, 0.2), -1.8em -1.8em 0 0em rgba(21,20,20, 0.2);
    }
    75% {
      box-shadow: 0em -2.6em 0em 0em rgba(21,20,20, 0.2), 1.8em -1.8em 0 0em rgba(21,20,20, 0.2), 2.5em 0em 0 0em rgba(21,20,20, 0.2), 1.75em 1.75em 0 0em rgba(21,20,20, 0.2), 0em 2.5em 0 0em rgba(21,20,20, 0.5), -1.8em 1.8em 0 0em rgba(21,20,20, 0.7), -2.6em 0em 0 0em #151414, -1.8em -1.8em 0 0em rgba(21,20,20, 0.2);
    }
    87.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(21,20,20, 0.2), 1.8em -1.8em 0 0em rgba(21,20,20, 0.2), 2.5em 0em 0 0em rgba(21,20,20, 0.2), 1.75em 1.75em 0 0em rgba(21,20,20, 0.2), 0em 2.5em 0 0em rgba(21,20,20, 0.2), -1.8em 1.8em 0 0em rgba(21,20,20, 0.5), -2.6em 0em 0 0em rgba(21,20,20, 0.7), -1.8em -1.8em 0 0em #151414;
    }
  } */



/* Added new Loader */
  .lds-ripple {
    /* display: inline-block; */
    position: relative;
    width: 180px;
    height: 180px;
    margin: 50px auto;
  }
  .lds-ripple div {
    position: absolute;
    border: 4px solid red;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    5% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
  